@media only screen and (max-width:  575.98px) {

	.header-card-dash, .header-card-user-dash {
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 15px;
		padding-bottom: 15px;
		width: 100%;
		border-bottom: 1px solid rgba(20,60,131,.10);
	}
	.content-card-dash {
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.navbar.scrolling-navbar {
		padding: 10px !important;
	}
	
	#mainNavbarCollapse.show {
		background-color:  var(--kabit-base-colorfucsia);
	}
	#mainNavbarCollapse .active {
		color: var(--kabit-base-colorwhite) !important;
    	border-bottom: 1px solid  var(--kabit-base-colorwhite);
	}
	.navbar.navbar-dark .breadcrumb .nav-item .nav-link, .navbar.navbar-dark .navbar-nav .nav-item .nav-link {
		color: var(--kabit-base-colorwhite);

	}
	.dash-gral {
		width: 40.75px!important;
		height: 40.75px!important;
		padding: 8px!important;
	}
	.dash-user-name {
		top:10px;
	}
	.statistic_class {
		padding-bottom: 15px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.timer-control {
		width: 70px;
		height: 70px;
		border-radius: 55px;
	}
	.timecontrols label.text-control {display:none;}
	.card-header {
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 15px;
		padding-bottom: 15px;

	}
	.padding-modal {
		padding-left: 10px;
		padding-right: 10px;

	}
	.bloc-icon-info button, .bloc-icon-info2 button {display: none;}

	.modal-footer .btn-default {
		width: 100%!important;
		float: left;
		position: relative;
	}
	.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
		padding-right: 5px !important;
	}
	.rowFilter {
		width: auto;
		position: initial;	
		top: auto;
	}
	.rowFilter button {
		margin-left: 0px;
	}
	.card-body {
		padding: 0px 0px 0px 0px;
	}
	.container {
		padding-right: 5px;
		padding-left: 5px;
	}
	.card-body .rowFilter {
		margin-left: 15px!important;
	}
	.modal-footer .btn-default {
		padding:0px;
	}
	.usernav nav div.show {
		background: var(--kabit-base-colorfucsia);
		z-index: 20;
	} 
	.usernav nav div.show ul {
		height: 100%;
	}
	.usernav .navbar-light {
		position: absolute;
    	right: 0;
	}
	.usernav .navbar-light button {
    	width: 100%;
		text-align: right;
	}

	.navbar.navbar-light .navbar-toggler-icon {
		filter: invert(100%) sepia(0%) saturate(0%)  brightness(0%) contrast(100%);
	}

	.timecontrols button img {
		top: 1px;
		width: 31px;
	}
	.modal-header, .modal-body, .card-body, .card-title {
		padding: 15px 5px 35px 5px;
	}
	table.month {
		width: 100%;
	}
	ul.legend-calendar {
		width: auto;
	}
	.borderRad, .calendar table.month td.day.round-left {
		border-radius: 0!important;
		-webkit-border-radius: 0!important;
	}
	.flyout {
		display: initial;
	}
	.navbar .Ripple-parent {
		z-index: 9999;
		text-align: right;
		border: none!important;
		padding-right: 15px!important ;
	}
	
	.navbar .Ripple-parent .fa-5x {
		font-weight: 600;
		color: #19444b;
		font-size: 25px;
	}
	.mTopHeader {
		margin-top: 88px;
	}
	.navbar.scrolling-navbar {
		height: 82px;
		padding-bottom: 0px !important;
		padding-top: 0px !important;		
	}
	.mask-custom {
		backdrop-filter: initial;
	}
	.usernav .navbar-toggler {
		position: fixed;
		right: 10px;
		width: 75px;
	}
	.zindexTo9 {
		z-index: 9;
		margin-left: initial;
	}
}
