.container {
	max-width: 1300px;
}
:root {
	--kabit-base-color: #144583;
	--kabit-base-colorback: #7fb0ec;
	--kabit-base-gray: #9b9a9b;
	--kabit-destroy-color: #8F0012;
	--kabit-label-color: #0D4D90;
	--kabit-table-content-color: 4A4A4A;
	--kabit-base-colorfucsia: #d40f37;
	--kabit-base-colorwhite: #ffffff;
}
.flyout {
	display:flex;
	flex-direction: column;
	min-height:100vh;
	justify-content: space-between;
}
.dashboard {
	padding-top:10px;
}
.date_dashboard {
	width: auto;
}
.form-20 input[type="time"] {
	width: 70px !important
}
.datepicker input[type="text"] {
	border: none !important;
	color: #58AAEA !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	background-color: transparent !important;
	border-radius: 0px!important;
	border-bottom: 1px solid #BEC9DB !important;	
	width: 88px !important;
	padding: 0;
	top: 8px;
	position: relative;	
}
i.fa-calendar-alt{
	float: left;
	padding-left: 10px;
	top: -3px;
	position: relative;
	font-size: 18px;
	float: left;
	color: #2b4478;
} 

#home {
	min-height:80vh;
}

.home-feature-box .fa {
	font-size:6rem;
}
label {
	font-size: 0.8rem;
}
.gray-text {
	color: var(--kabit-base-gray);

}

.home-feature-box span {
	display: block;
	color:#111;
	font-weight:bold;
	margin-top:1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
	border-bottom:0;
}

.example-components-list li > a .fa {
	color:rgba(0,0,0,.35);
	float:right;
}
.to_right {
	float: right;
}
.admin-color {
	background-color: white;
}
.no-shadow {
	box-shadow: none;
	border-bottom: none;
}
.no-wrap {
	white-space: nowrap;
	overflow: hidden;
}
.navbar.navbar-dark .breadcrumb .nav-item .nav-link, .navbar.navbar-dark .navbar-nav .nav-item .nav-link {
	font-size: 15px;
	color: #144583;
}
.navbar.navbar-dark .breadcrumb .nav-item .nav-link:hover, .navbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
	color: #144583;
}
.avatar img {
	width: 50px;
}
.listscroll {
	max-height: 172px;
	overflow: hidden;
	overflow-y: scroll;
	font-size: 12px;
	scrollbar-width: thin;
	scrollbar-color:#144583 rgba(20,69,131,.01);
	cursor: pointer;
}
.listscroll .row {
	padding-bottom: 15px;
}
.listscroll i {
	font-size: 1.3rem;
}

.counter {
	position: absolute;
	top: 1px;
	margin-left: -7px;
	box-shadow: none;
	font-size: 9px;
	padding: 1px 5px;
	background-color: #f9b10c;
}

.listscroll::-webkit-scrollbar {width: 1px!important;}
.listscroll::-webkit-scrollbar-track { background : #555999!important;border-radius: 1px!important;}
.listscroll::-webkit-scrollbar-thumb { background : #555999!important;border-radius: 1px!important;box-shadow:  0 0 6px rgba(0, 0, 0, 0.5)!important;}

.listscroll .nav-link {
	display: initial;
	float: right;
	padding: 0px;
	top: -10px;
}

.BarHour {
	width:100%;
	height: 10px;
    background: #dcdbdb;
	margin-bottom: 20px;
}
.BarHour .complete {
    height: 10px;
    z-index: 9;
    background: #1e1ac5;
}
.title-text {
	color: #000c48;
}
.h5-text {
	display: inline;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 400;
	font-size: 1rem;
}
h5 {
	font-size: 17px;
	letter-spacing: 0.67px;
}
.basic-text {
	color: #9bd7d8;
}

.btn-default {
	background-color: transparent !important;
	color: #87898c !important;
	border: 1px solid #d3e5f9 !important;
}

.modal-footer .btn-default {
	width: 26%;
	font-size: 12px;
	border-radius: 8px;
	text-transform: uppercase;
	margin-right: 20px !important;
	height: 46px;
	padding-top: 11px;
}
.modal-sm .modal-footer .btn-default {
	width: auto;
}
.btn-confirm-1 {
	color: white!important;
	background-color: var(--kabit-base-colorfucsia)!important;
	border: 1px solid var(var(--kabit-base-colorfucsia))!important;
	border-radius: 23px!important;
}
.btn-cancel-1 {
	color: var(--kabit-base-color)!important;
	border-color: var(--kabit-base-color)!important;
	background-color: transparent!important;
	border: 1px solid var(--kabit-base-color)!important;
	border-radius: 23px!important;
}
.btn-modal-1 {
	color: var(--kabit-base-color)!important;
	background-color: transparent!important;
	border: none!important;
	font-size: 20px;
	padding: 0;
}
.btn-destroy-1 {
	color: var(--kabit-base-colorfucsia)!important;
	background-color: transparent!important;
	text-transform:initial!important;
	border: 1px solid var(--kabit-base-colorfucsia)!important;
	border-radius: 23px!important;
}
.btn-destroy-1:hover {
	color: var(--kabit-base-colorfucsia)!important;
}
.kabit-sidebar {
	background-color: var(--kabit-base-colorfucsia);
}
.kabit-sidebar a {
	padding-right: 40px!important;
    font-weight: 400!important;
    font-size: 1em!important;
}
.btn-confirm-1:hover {
	color: white!important;
}

.btn-destroy-1:hover, .btn-cancel-1, .btn-confirm-1 {
	box-shadow: none!important;
}


body {
	color: var(--kabit-base-color);
	font-family: 'Open Sans', sans-serif;
	background-color: #F5F7FA!important;
	table-layout: fixed;
}
.normal-text {
	color: var(--kabit-base-color);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #a6b9d0!important;
	font-size: 13px !important;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: var(--kabit-base-color);
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: var(--kabit-base-color);
}


input, input.form-kabit {
	border: 1px solid #ebf6f6;
	background-color: transparent!important;
	width: 90%;
	padding: 10px;
	color: var(--kabit-base-color);
	height: auto;
	border-radius: .25rem;
}
.form-kabit::placeholder {
	color: #a8dbdc
}
.modal-dialog .modal-content {
	box-shadow: none;
	border: 1px solid #144583;
	border-radius: 6px; 
}
.modal-dialog .modal-body {
		padding-top: 0px;
		padding-bottom: 0px;
}
.modal-dialog hr {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}
.box-form {
	padding: 30px!important;
}
.signin input[type="email"], .signin input[type="password"] {
	width: 100%;
}
.signin .title-signin {
	padding: 30px;
	border-bottom: 1px solid #ebf6f6;
}
.btn {
	border: 1px solid #eff7f7;
	box-shadow: none;
}
.card {
	box-shadow: none;
	font-weight: 100;
}
.card-transparent {
	background-color: transparent
}
.icon-user-list {
	float: right;
    padding-right: 10px;
	font-size: 1.5rem;
	color: #aeaeae;
}


.listscroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.listscroll::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.listscroll::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.listscroll::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.listscroll::-webkit-scrollbar-thumb:active {
  background: #000000;
}
.listscroll::-webkit-scrollbar-track {
  background: #37dac1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.listscroll::-webkit-scrollbar-track:hover {
  background: #666666;
}
.listscroll::-webkit-scrollbar-track:active {
  background: #37dac1;
}
.listscroll::-webkit-scrollbar-corner {
  background: transparent;
}
.link-basic {
	margin: 0.6rem;
	color: #747373;
}
.avatar img.medium-size {
	width: 85px;
}
.fs15 {
	font-size: 15px;
}
.dropdown .btn {
	font-size: 9px;
	border: 0px;

	font-variant-caps: normal;
}
.dropdown .btn:hover {
	box-shadow: none;
	color: var(--kabit-base-color)

}
.titlenav {
	color: #c6cad5 !important;
	font-weight: 400;
}
.nav .nav-link {
	color: #144583;
}
.nav-link.active::before  {

}
.nav-link.active {
	background-color: transparent!important;

}
.select-wrapper input.select-dropdown {
	border: 1px solid #D4E5F9 !important;
	background-color:white !important;
	padding-left: 20px;
	font-size: 0.8em;	
	border-radius: 5px!important;
}
.select-wrapper span.caret {
    left: 100%;
}
.caret {
	z-index: 9;
	color: #00416A!important;
	right: 10px!important;
}
.dataTables_length select {
	display: inline!important;
}
tr {
	cursor: pointer;
}
.statistic_class tr {
	cursor: auto;
}
.card-header {
	background-color: transparent;
	border: none;
}
.zoom {
	float:left!important;
}
.column-report {width: 29px!important;padding: 0!important;overflow: hidden;position:relative; padding-left: 3px !important; text-align: center;}
.column-report-image {width: 75px!important;padding: 0!important;overflow: hidden; padding-left: 5px !important;padding-right: 5px !important;}
.column-report-name {width: 125px!important;padding: 0!important;overflow: hidden;}
.rounded-little-day {
	width:10px!important; 
	height: 10px!important; 
	border-radius: 5px; 
	margin: 0 auto;
	margin-top: 2px;
}
.litle-day {
	width: 100%;
	text-align: center;
	position: absolute;
}
.pickr {
	position: relative;
	height: 2em;
	width: 2em;
	padding: .5em;
	border-radius: .15em;
	cursor: pointer;
	background: transparent;
	transition: background-color .3s;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
	box-sizing: border-box;
	background-color: black;
  }
  .nav-link {float:left}
  .nav-item span.nav-item-item {
	  float:left; 
	padding: .5rem 1rem; 
	margin-left: -30px;}
.avatar span {
	background: var(--kabit-base-colorback);
    color: white;
	padding: 12px;
	text-align: center;
	display: block;
}	
.avatar .dash-user {
	width: 90px;
	height: 90px;
	background-size: cover;
	background-position: center;
}
.dash-gral {
	width: 50px!important;
	height: 50px!important;
	background-size: cover!important;
	background-position: center!important;
}
.little-icon-user {
	width: 53px!important;
	height: 53px!important;
	background-size: cover!important;
	background-position: center!important;
	background-color: #63aeeb;
}

.dash-user-name {
	position: relative;
	top: 20px;
	font-size: 14px;
}
.dash-avatar {
	border: 1px solid #e5e5e5;
	position: relative;
	float: right;
	border-radius: 90px;
	top: 3px;
	left: -24px;
}
.separator {
	padding-left: 15px;
	line-height: 1em;
}
.bubble-little-size {
	width: 15px!important;
	height: 15px!important;

}

.card-request  {
	margin-bottom: 40px;
}
#loading {
	position: absolute;
	left: 0;
	top: 0;
	width: 66px;
	height: 66px;
	z-index: 0;
}
.preloader-wrapper {
	z-index: 9999;
	position: fixed;
	top: 45%;
	width: 80px;
	height: 80px;
	right: 45%;
}
.input-field {
	margin-bottom: 20px;
}
.stepper .box {
	flex: 1 1!important
}
.fc-button-primary {
	color: #fff !important;
	background-color: var(--kabit-base-color) !important;
	border-color: transparent !important;
	padding: 3px!important;
}

.fc-left {
	width: 150px;
	overflow: hidden;
}
.fc-left h2 {
	position: absolute;
	font-size: 1.2rem!important;
	overflow: hidden!important;
}

li.SortableItem {
	font-size: 1rem;
	padding: 15px 20px;
	list-style: none;
	background-color: var(--kabit-base-color);
	color: #fff;
	margin: 2px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: move;
}
ul li.SortableItem {
	list-style-type: none;
}
ul {padding: 0;}

.pickr {
	width: 120px;
	height: 1em;
	float: left;
}

.table-hours {
	width: 100%;
}
.table-hours .table-hours-header {
	height: 50px;
}
.table-hours .table-hours-header th {
	width: 15px;
	font-size: 9px;
	cursor: auto;
}
.table-hours tr.table-hours-item {
	height: 24px;
	position: relative;
}
.table-hours .table-hours-item td {
	transition: background-color 1s ease-out;
	background-color: #fafafa;
	/*border-right: 1px solid white;*/
}
.little-button {
	width: 20px;
	height: 20px;
	background:	var(--kabit-base-color);
	color: white;
	font-size: 10px;
	line-height: 20px;
	text-align: center;
	border-radius: 5px;
}
.md-form {
	margin-top: 0px !important;
	margin-bottom: 13px !important;
	width: 60%;
	float: left;
}
.hours-block .md-form {
	margin-bottom: 0px;
	margin-top: 0px;
}
.block-hours-mandatory {
	position: absolute;
	top: 0px;
	height: 24px;
	border-radius: 20px;
}
.round-right, .round-left {
	/*border-radius: 0!important;*/
}
.selected {
	background-color: royalblue;
}

.dataTables_paginate .current {
	color: #4a4a4a;
	border: none!important;
}
.dataTables_paginate .previous, .dataTables_paginate .next {
	color: #4a4a4a!important;
	font-weight: 600;
}

.dataTable {
	border: 1px solid #D4E5F9!important;
	border-radius: 5px;
	border-top-right-radius: 5px;
    border-top-left-radius: 5px;
	border-bottom: 0px !important;
	max-width: 100%;
	width: 100%!important;
}

table.dataTable.no-footer {
	border: none;
}
.dataTables_length select {
	display: inline!important;
}
tr {
	cursor: pointer;
}
.dataTables_wrapper {
	color: gray;
}
table.dataTable thead tr {
	background-color: #eff5fc;
	color: #4A4A4A;
}
table.dataTable thead th, table.dataTable tfoot th {
	font-weight: normal!important;
	border-bottom: 2px solid #f0f0f0;
}
table.dataTable  tr {
	height: 50px;
}
table.dataTable tbody tr td, .table-time .row {
	border-bottom: 2px solid #f5f9fd
}
table.dataTable.report1 tbody tr {
	height: 60px;
}
.table-time .row {
	margin-bottom: 15px;
	padding-bottom: 10px;
}
 
table.dataTable thead th, table.dataTable thead td, table.dataTable tbody th, table.dataTable tbody td {
	padding: 12px 20px 0px 20px;
}
table td, .dataTables_processing {
	font-size: 12px;
	font-weight: 400;
	color: var(--kabit-table-content-color)!important;
}

.dataTables_info {
	position: absolute;
	z-index: 0;
	right: 0;
	line-height: 32px;
	padding-right: 10px;
	z-index: 9;
}
.card-header {
	background-color: transparent;
	border: none;
}
table.dataTable thead .sorting::before, table.dataTable thead .sorting_asc::before, table.dataTable thead .sorting_desc::before, table.dataTable thead .sorting_asc_disabled::before, table.dataTable thead .sorting_desc_disabled::before, table.dataTable thead .sorting::after, table.dataTable thead .sorting_asc::after, table.dataTable thead .sorting_desc::after, table.dataTable thead .sorting_asc_disabled::after, table.dataTable thead .sorting_desc_disabled::after {
	display: none;
}
.dataTables_info, .dataTables_length, .dataTables_filter, .dataTables_paginate {
	font-size: 0.8rem;
	color: 	var(--kabit-base-color)!important;

}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
	color: 	#a7aaad !important;
}

.dataTables_wrapper {
	position: relative;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	background: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	color: #a7aaad!important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	color: #a7aaad !important;
	border: none!important;
	background: none;
}
.dataTables_paginate .previous, .dataTables.paginate .next {
	color: 	var(--kabit-base-color)!important;
}
.dataTables_wrapper .dataTables_paginate {
	float: right;
	width: 100%!important;
	background:#eff5fc;
	padding-right: 90px;
	border: 1px solid#D4E5F9;
	border-top: none;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.dataTables_filter input {
	background-color: white !important;
	border: 1px solid #d3e5f9;	
}
.dataTables_length {
	position: absolute;
	bottom: 0px;
	right: 29px;
}
.user-dashboard table h4 {
	font-size: 17px;
	font-weight: 600!important;
}

.user-dashboard table {
	border: none!important
}
.user-dashboard table.dataTable thead tr {
	background-color: transparent!important;
}
.user-dashboard table.dataTable thead th, table.dataTable tfoot th, .table-time .th {
	color: #58AAEA!important;
	text-transform: uppercase!important;
	font-size: 12px!important;
	letter-spacing: 1.12px!important;
}
.hue-picker {float: left}

.activeTW {
	color: black!important;
	font-weight: 400;
}
.TW {
	border-radius: 8px;
	padding: 10px;
	margin-bottom: 15px;
	cursor: pointer; 
	background-color: #7fb0ec4a;
	color: #878787;
	width: 90%;
	float: left;
}
.col .fa-trash-alt {
	float: right;
	line-height: 45px;
	font-size: 21px;
	font-weight: 100;
	right: -8px;
	position: relative;
}
.fa-info-circle {
	float: left;
	padding-right: 8px;
}

.lfont {
	font-size: 0.7em;
}
  
.navbarUserData {
	font-size: 14px;
	font-family: "Open Sans";
	color: #6cb4ec;
	font-weight: 400;
}
.btn-icon {
	border: none;
	padding: 0;
	background-color: transparent;
}
.form_field_label , .form-check-label{
	font-size: 14px;
	float: left;
	/*width: 100%;*/
}
input::placeholder {
	font-size: 12px;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	font-size: 12px;
}
::-moz-placeholder { /* Firefox 19+ */
	font-size: 12px;
}
:-ms-input-placeholder { /* IE 10+ */
	font-size: 12px;
}
:-moz-placeholder { /* Firefox 18- */
	font-size: 12px;
}
.sangria-left-55 {
	padding-left: 55px;
}
.form-check {
	padding-left: 0px!important;
}
.card-request .avatar .rounded-circle {
	margin-right: 15px;
}
.preloader-wrapper .spinner-layer .circle-clipper .circle {
	border-width: 0.288rem;
}
.rounded-day {
	width: 30px !important;
	height: 30px!important;
	border-radius: 20px!important;
	text-align: center;
	color: white;
	line-height: 29px;	
	margin: 0 auto;
}
.gray-color {
	color: #747373;
}
.mask-color {
	width: 15px;
	height: 15px;
	border-radius: 10px;
}
.hidden {
	display: none;
}
.showing {
	display: inline;
}
.isloading {
	/*opacity: 0.5;*/
} 
.border-bottom-dash {
	border-bottom: 1px solid#e5e5e5;

}
.no-padding-top  {
	padding-top: 0px!important;
	
}
.header-card-dash {
	padding-left: 76px;
	padding-right: 76px;
	padding-top: 25px;
	padding-bottom: 25px;
	width: 100%;
	border-bottom: 1px solid rgba(20,60,131,.10);
}
.content-card-dash {
	padding-top: 25px;
	padding-bottom: 25px;
	padding-left: 40px;
	padding-right: 40px;	
} 
.header-card-user-dash {
	padding-left: 60px;
	padding-right: 60px;
	padding-top: 34px;
	padding-bottom: 25px;
	width: 100%;
}
.content-card-user-dash {
	padding-top: 30px;
	padding-bottom: 25px;
	padding-left: 40px;
	padding-right: 40px;	
} 
.tablecell {
} 
.borderRequest {
	border: 1px solid #7d7d7d!important
}

table.month {
	border-spacing: 2px!important;
    border-collapse: separate;
}
.month-container{
	height: 235px!important;
}

.form_field_label, .form-check-label {
	padding: 0px 0px 10px 0px;
}
.row {
	margin-right: 0px!important;
	margin-left: 0px!important;
}
hr {
	margin-top: 2rem!important;
	margin-bottom: 2rem!important;
}
.btn-default {
	box-shadow: none;
}
.btn-default:hover {
	color: var(--kabit-base-color);
}
.card label {
	color: #9B9B9B!important
}
.alert {
	margin-bottom: 0px!important;
	z-index: 1;
	padding-bottom: 0px;
}
.kabit-alert-warning .modal-content {
	background-color: #fff3cd;
	border-color: #ffeeba;
}
.kabit-alert-danger .modal-content {
	background: #f8d7da;
	border-color: transparent;

}
.danger {
	background: #f8d7da!important;

}
.card-body .btn-sm {
}

.select-wrapper input.select-dropdown {
	font-size: 0.85rem!important;
}
.col-6 .dropdown-content {
	min-width: 235px;
}
.calendar .calendar-header {
	margin-bottom: 40px!important;
}
ul.legend-calendar li:hover {

}
ul.legend-calendar {
	list-style: outside none none;
	position: absolute;
	top: 55px;
	left: 10px;
	width: 825px;
	border-bottom: 1px solid #707e9e3d;
	padding-bottom: 6px;
	min-height: 31px;
}
.calendar .calendar-header table th {
	font-size: 17px;
	font-weight: normal;
}
.calendar .month-title {
	color: #69789a;
}
.calendar .day-header {
	color: #b7bfc7;
}
.calendar .day-content {
	color: #7a86a5;
	padding: 3px 4px !important;
	border: 1px solid transparent;
}
.fa-trash-alt {
	cursor: pointer;
}
.zindexTo9 {
	z-index: 9;
	margin-left: -22px;
}
.scrolling-navbar {
	padding-bottom: 10px !important;
}
.font-step {
	font-size: 0.9rem;
	font-weight: 600;
}
.calendar .calendar-header .year-title {
	font-size: 1rem!important;
}
.calendar table.month th.month-title {
	text-align: left;
}
.calendar .months-container .month-container {
}
.auto-width {
	width: auto!important;
}
.width-100 {
	width: 100%!important;
}
.width-140 {
	width: 140px!important;

}
.usernav {
	height: 67px;
	border-top: 1px solid #F1F1F1 !important;
	position: relative;
	width: 100%;
	top: -4px;	
	padding-top: 0px!important;
}

.slogan {
	padding-left: 25px;
	padding-top: 20px;
	float: right;
}
.dropdown .dropdown-menu, .dropup .dropdown-menu, .dropleft .dropdown-menu, .dropright .dropdown-menu {
	padding: 0px!important;
	overflow: hidden;
}
.dropdown-divider {
	margin: auto!important;
}
.dropdown-header {
	color: var(--kabit-base-color);	
	font-weight: 600;
	padding-top: 12px;
	padding-bottom: 12px;
}

.navbar.scrolling-navbar {
	padding: 0px!important;
	margin: 0 auto!important;
	height: 67px;
}


.nav {
	font-size: 15px;
}
#mainNavbarCollapse .active {
	color: black!important;
	border-bottom: 1px solid black;
	font-weight: 600;
}

.navbar {
	font-family: "Open Sans";
	font-weight: 600!important;
	letter-spacing: 0.55px;

}
.secondnav {
	border: none!important;
	background-color:  #144583!important;

}
.secondnav ul {
	justify-content: center;
}
.secondnav  .nav-link {
	color: white!important;
	font-size: 14px;
	font-weight: 600;
}
.secondnav .active {
	color: white!important;
	border-bottom: 1px solid #D8D8D8;
	font-weight: 600;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
	padding-left: 5px !important;
}
.navbar {box-shadow: initial;}

.mdb-select {
	margin-right: 11px!important;
	font-size: 13px;
}

.select-dropdown {
	border: 1px solid #D4E5F9	 !important;
}

.dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item:active,
    .dropup .dropdown-menu .dropdown-item:hover,
    .dropup .dropdown-menu .dropdown-item:active,
    .dropleft .dropdown-menu .dropdown-item:hover,
    .dropleft .dropdown-menu .dropdown-item:active,
    .dropright .dropdown-menu .dropdown-item:hover,
    .dropright .dropdown-menu .dropdown-item:active {
	color: red!important;
}
.dropdown-item:hover, .dropdown-item:focus {
	color: red!important;
}
table.month:hover {

}
.card-body {
	padding:0 ;
}

.modal-header, .modal-body, .card-body , .card-title {
	padding: 15px 28px 35px 28px;
}

.modal-header-form {
	padding: 10px 28px 10px 28px!important;
}
.card-body {
	margin-right: 15px;
	margin-left: 15px;
}
.user-dashboard h4 {
	text-transform: uppercase!important;
	font-size: 17px;
	font-weight: 600!important;
	color: var(--kabit-base-colorfucsia);
}
.profile-menu {
	border: 1px solid #14458370;
	border-radius: 6px;
	/*min-height: 250px;*/
	width: 300px;
	margin-top: 52px;
}
.mask-custom {
	backdrop-filter: blur(5px);
	/*background-color: rgba(255, 255, 255, .15);*/
}
.ztop {z-index: 999}
.side-nav {
	z-index: 99999;
}

.mTopHeader {
	margin-top: 72px;
}

.profile-menu .dropdown-item {
	padding-left: 36px!important;
	padding-right: 36px!important;
	padding-top: 10px!important;
	padding-bottom: 10px!important;
}
.button-fucsia {
	color: var(--kabit-base-colorfucsia)!important;
	border: 1px solid var(--kabit-base-colorfucsia)!important;
	border-radius: 17px!important;
	text-align: center!important;
	line-height: 35px;
	font-size: 10px!important;
	display: block;
}
.profile-menu .nav-link {
	padding: 0!important;
}
.profile-menu .button-fucsia {
	padding: 0!important;
}
.user-department {
	color: #7C86A2;
	font-size: 14px;
}
.menu-top {
	position: relative;
}
.menu-top .nav-link {
	padding-left: 18px!important;
	padding-right: 18px!important;
}
.dashboard .card {
}
.shadow-me {
	box-shadow: 1px 1px 2px 0.9px rgba(88,111,158,.15) !important;

}
.dashboard .card-transparent {
	box-shadow: none!important;
}
.dashboard .card-body {
	padding: 0px;
}
.time .card-body {
	padding: 0px;
	margin: 0px;
	padding-top: 40px!important;

}
h5.card-title {
	font-weight: 600!important;
	padding: 0!important;
	color: var(--kabit-base-colorfucsia);
}
.datepicker {
	border-bottom: none!important;
	padding: 7px;
	border-radius: 3px;
	color: #144583!important;
	width: 145px;
	float: left;
}
.nopadding {
	top: -10px;
}
div.datepicker > div div::before, div.datepicker > div div::after {
	border: none!important;
}

.datepickerForm {
	border-bottom: none!important;
	padding: 7px;
	border-radius: 3px;
	color: #144583!important;
	width: 215px;
}
div.datepickerForm > div div::before, div.datepickerForm > div div::after {
	border: none!important;
}
div.datepickerForm input[type="text"] {
	border: none!important;
    text-align: right;
    top: -15px;
    position: relative;	
}
.label-inline {
	margin-top: 9px;
	margin-right: 7px;
	color: #9c9b9c;
	margin-left: 20px;
	float: left;
}

.dashboard .dashboard-p-item p {
	width: 100%;
	float: left;
	font-weight: 400;
	color: var(--kabit-label-color);
}
.card-body .row {
	margin-left: 0px!important;
	
}

h5.card-title {
	text-transform: uppercase;
}

.icon-in-textbox {
	position: absolute;
	z-index: 9;
	left: 170px;
	font-size: 21px;
	top: 17px;
	color: #7fb0ec;
}
.icon-in-button {
	position: absolute;
	right: 320px;
	top: 96px;
	color: #a3a3a3;
}

.dt-buttons {
	position: absolute!important;
	float: left!important;
	right: 250px;
	top: 4px;
	z-index: 9;
}
.dt-button {
	background-image: none!important;
}
.btn.btn-sm {
	padding: 0.5rem 1.8rem!important;
}
.reports .dt-buttons {
	right: 0px!important;
	top: -80px!important;
}
.reports .icon-in-button {
	position: absolute;
	right: 320px;
	top: -100px;
	color: #a3a3a3;
}

.dataTables_paginate {
	max-width: 1252px !important;
	margin-right: 0px !important;
	margin-top: -3px !important;
	left: 0px;
	position: absolute;	
	width: 1242px !important;
}

.dashboard .dataTables_paginate {
	max-width: 1252px !important;
	width: 1260px !important;

}
.rowFilter {
	width: 80%!important;
	top: 25px!important;
	position: absolute;
}
.rowFilter button{
	margin-left: 20px;
}

.rowFilter label {
	float: left;
	padding-top: 12px;
	padding-right: 10px;
}
.rowFilter .mdb-select {
	float: left;
	max-width: 160px;
}

.md-form input:not([type]), .md-form input[type="date"]:not(.browser-default), 
.md-form input[type="datetime-local"]:not(.browser-default), .md-form input[type="datetime"]:not(.browser-default), 
.md-form input[type="email"]:not(.browser-default), .md-form input[type="number"]:not(.browser-default), 
.md-form input[type="password"]:not(.browser-default), .md-form input[type="search-md"], .md-form input[type="search"]:not(.browser-default), 
.md-form input[type="tel"]:not(.browser-default), .md-form input[type="text"]:not(.browser-default), .md-form input[type="time"]:not(.browser-default), 
.md-form input[type="url"]:not(.browser-default), .md-form textarea.md-textarea {

    border: 1px solid #A1B5CE;
    border-radius: 6px;
    padding-right: 10px;
    color: #0D4D90;
    padding-left: 10px;
	font-size: 13px;
	/*width: 89%!important;*/
	background: white;
	border-bottom: 1px solid #A1B5CE;
	font-weight: 300;
}
.select-wrapper input.select-dropdown {font-size: 10px!important;}
.md-form textarea.md-textarea {
	padding-left: 10px!important;
	padding-top: 5px;
	padding-bottom: 5px;
}
.w50 input {
	width: 50px!important;
}

.md-form .validate {
	margin-bottom: auto!important;
}
.switch {
	float: left;
	width: 100%;
}
.datepic {
	float: left;
	width: 100%;
}
.switch label .lever {
	height: 1.5535rem!important;
	width: 3.15rem!important;
}
.switch label .lever::after {
	width: 1.5535rem!important;
	height: 1.5535rem!important;
	top: auto!important;
}
.table-time {
	font-weight: 400;
}
.first-cell {
	font-size: 15px!important;
	font-family: "Open Sans";
	font-weight: 500;
	color: var(--kabit-base-color)!important;

}
.other-cell {
	font-size: 15px!important;
	color: #7C86A2!important;
}
.card-header {
	padding-left: 60px;
	padding-right: 60px;
	padding-top: 37px;
	padding-bottom: 37px;
	border-bottom: 1px solid #D8D8D8;
}
.avatar-header {
	margin-bottom: 24px;
	height: 110px;
	padding-top: 24px;
}
.dashboard .card-body {
	margin-right: 0px;
	margin-left: 0px;
}
.statistic_class table.dataTable thead th, table.dataTable thead td, .statistic_class table.dataTable tbody th, .statistic_class table.dataTable tbody td {
	padding: 0px;
}
.statistic_class {
	padding-bottom: 25px;
	padding-left: 60px;
	padding-right: 55px;
}
.percent {
	position: absolute;
	left: 15px;
	background: white;
	border-radius: 120px;
	width: 90px;
	height: 90px;
	top: 16px;
	line-height: 83px;
	font-size: 21px;
	color: #59abea;
	text-align: center;
}
.popover-enter-done {
	overflow: hidden;
}
.nav-link .Ripple-parent {
	max-width: 100%;
}
.dropdown-item:focus,.dropdown-item:hover {
	background: none!important;
	box-shadow: none!important;
}
.table-act {
	padding-left: 0!important;
	padding-right: 0!important;
}
.grey-text {
	color: #7C86A2!important;
}
.modal-backdrop {
	background-color: #fff!important;
}
.modal-backdrop.show {
	opacity: .8!important;
}
.title-user-name {
	padding-left: 10px;
	text-transform: capitalize!important;
	color: var(--kabit-base-color);
	font-size: 14px;
	font-family: "Open Sans";
	font-weight: normal;
	top: 12px;
	position: relative;	
	text-align: left;
	max-width: 150px;
	overflow: hidden;
	height: 22px;
}

.btn-white:not([disabled]):not(.disabled):active, .btn-white:not([disabled]):not(.disabled).active, .show > .btn-white.dropdown-toggle {
	box-shadow: none!important;
}
.dropdown-toggle::after {
	border-top: .6em solid;
	border-right: .6em solid transparent;
	border-left: .6em solid transparent;
	position: relative;
	right: -15px;
	float: right;
	height: 19px;
}
button.Ripple-parent.dropdown-toggle {
	padding: 0 !important;
	padding-right: 20px !important;
}
.modal-dialog {
	max-width: 689px !important;
}
.modal-sm {
	max-width: 450px!important;
}
.no-padding {
	padding: 0px!important;
	padding-top: 25px!important;
}
.no-margin {
	margin: 0px!important;
}
.no-margin-top {
	margin-top: 0px!important;
	margin-bottom: 1rem!important;
}
.w400 {
	font-weight: 400;
}
.padding-modal {
	padding-left: 50px;
	padding-right: 50px;
}
.modal-header {
	/*padding-left: 68px!important;*/
	/*margin-bottom: 17px;*/
}
.modal-body {
	padding-top: 15px!important;
}
h4.modal-header {
	font-size: 22px;
	font-weight: 400 !important;
	letter-spacing: 0.87px;
	
}
.bloc-form-with-text {
	border: 1px solid #A1B5CE;
	border-radius: 6px;
	position: relative;
	height: 34px;
	padding-top: 5px;
	padding-left: 13px;
}
.form_field_label {
	/*float: left;*/
}
.bloc-form-with-text .md-form {
	float: right;
	margin-bottom: 0px;
}
.bloc-form-with-text .md-form .form-control {
	border:none!important;
	float:right;
	padding: 0px;
	/*width: 40px!important;*/
}
.bloc-form-with-text .md-form .form-control-date {
	border:none!important;
	float:right;
	padding: 0px;
	width: 80px!important;
}
.md-form .form-control {
	padding: .375rem .75rem;
}
.form-20 {
}
input::placeholder {
    font-size: 13px;
	font-weight: 400;
}
.bloc-icon-info {
	float: left;
	padding-top: 12px;
}
.form_field_label-big {
	float: left;
	font-size: 15px;
	font-weight: 400;
}
.modal-footer {
	padding: 0;
	/*margin-top: 32px;*/
}
.p-3  {
	padding: 2px 10px 2px 10px !important;
	background-color: #f0f0f0;
	font-size: 0.88em;
}
.modal-title {
	font-size: 22px!important;
	font-weight: 500!important;
	color: var(--kabit-base-colorfucsia);
}

#map {
	height: 100%;
  }
.icon-map img {
	width: 38px;
}

.mw800 {
	max-width: 800px!important;
}

.icon-info {
	background-color: transparent !important;
	border: none !important;
	color: #144583 !important;
	font-size: 16px !important;
	right: 0;
	top: 0;
	padding:0;
	margin:0;
}
.btn.icon-info:hover, .btn.icon-info:focus, .btn.icon-info:active {
	outline: none;
	box-shadow: none;
}
.timecontrols .col-3 {
	text-align: center;
	font-weight: 500;
}
.timecontrols button {
	border: none!important;
}
.timecontrols button img {
	display: block;
	margin: 0 auto;
	position: relative;
	top: 8px;
}
.timecontrols label.text-control {
	font-size: 14px;
	color: #58AAEA!important;
	letter-spacing: 1.12px;
	position: relative;
	top: 15px;
}
.blue-text  {
	font-size: 12px;
	color: #58AAEA!important;
	letter-spacing: 1.12px;

}
.timer-count {
	font-size: 33px;
	color: #144583
}
.timer-pause {
	font-size: 33px;
	color: #81a0c8;
}
.btn:hover, .btn:focus, .btn:active {
	box-shadow: none;
}
.titleTable {
	font-weight: 500;
	letter-spacing: 1.67px;
}
.btn:active, btn.active:focus, .btn:active:focus {
	background-color: transparent!important;
	border-color: transparent!important;
}


.md-form input:not([type]), .md-form input[type="date"]:not(.browser-default), .md-form input[type="datetime-local"]:not(.browser-default), .md-form input[type="datetime"]:not(.browser-default), .md-form input[type="email"]:not(.browser-default), .md-form input[type="number"]:not(.browser-default), .md-form input[type="password"]:not(.browser-default), .md-form input[type="search-md"], .md-form input[type="search"]:not(.browser-default), .md-form input[type="tel"]:not(.browser-default), .md-form input[type="text"]:not(.browser-default), .md-form input[type="time"]:not(.browser-default), .md-form input[type="url"]:not(.browser-default), .md-form textarea.md-textarea {
	background-color: transparent;
}

.user_request .datepicker.md-form input[type="text"] {
	border: 1px solid #A1B5CE !important;
	border-radius: 6px !important;
	padding: 10px 15px 10px 15px;
}

.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active,
.show > .btn.dropdown-toggle {
  box-shadow: none!important;
  background-color: #144583!important; 
}
.btn:not([disabled]):not(.disabled):active:focus, .btn:not([disabled]):not(.disabled).active:focus,
.show > .btn.dropdown-toggle:focus {
	box-shadow: none!important;
}	

.form-check-input[type="checkbox"] + label::before {
	border: 2px solid #134582;
}
.calendar-card .card-body {
	padding-right: 0px;
	padding-left: 0px;
}
.calendar-card input.select-dropdown {
	width: 100%!important;
}
.dpm {
	width: 120px;
	float: left;
}
.icalendar-alt {
	padding-top: 20px !important;
}

.f16 {
	font-size: 16px;
}
.cpNB:first-child {
	box-shadow: none!important;
}
.timer-control {
	width: 110px;
	height: 110px;
	background-color: #F5F7FA !important;
	border-radius: 110px;
	padding: 0;
}
.btn-left { margin-right: 25px} 
.btn-center { } 
.btn-right { margin-left: 25px} 
.inputne {
	float: left;
	width: 50px;
	height: 24px;
	border-radius: 0;
}
.btnsum {
	border: 0px;
}

.companyform .row {
	margin-bottom: 0.5rem;
}
.companyform .label {
	margin-bottom: 0px;
}	
.kabit-alert-info p, .kabit-alert-danger p {
	padding-top: 10px;
}
.progress {
	height: 1.5rem;
}
.progress-bar {
	width: 1%;
	height: 1.5rem;
	color: black;
	font-size: 12px;
	text-align: left;
	padding-left: 19px;

}
.blocHours {
	text-align: center;
	padding-bottom: 0;
	font-size: 14px;
	bottom: -16px;
	position: absolute;
	background: red;
	color: white;
	width: 60%;
	left: 135px;
	padding-left: 50px;
	padding-right: 50px;
	font-weight: 400;
}
.invalid-color-feedback, .invalid-teoric-feedback, .invalid-max_pause_time-feedback {
	display: none;
	color: red;
}
.hours-block .fa-plus {
	cursor: pointer;
}

.hours-block .fa-minus {
	position: absolute;
	right: -4px;
	top: 6px;
	font-size: 10px;
	background-color: #dd4a93;
	cursor: pointer;
}
div.day-content:title {
	color: red
}
.h50T {
	margin-top: 100px;
}

.pf-image {
	width: 100px;
	height: 100px;
	float: right;
	position: absolute;
	background-size: cover;
	background-position: center;
	top: 0;
	right: 20px;
	border-radius: 50%;
	top: -65px;
	cursor: pointer;
	filter: grayscale(100%);
}
.pf-image i {
	font-size: 57px;
	width: 100%;
	top: 20px;
	position: relative;
	left: 20px;
	opacity: 0.5;
	color: white;
}
.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active, .show > .btn.dropdown-toggle {
	background-color: transparent!important;
}

.navbar.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 48, 55, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")!important;
}
.menu-user-image {
	position: absolute!important;
	max-width: 200px;
	width: 200px;
	top: -2px;

}
.list_attach_top {
	margin-top: 10px;
	font-size: 0.8rem;

}
.list_attach {
	background-color: #f5f5f59c;
	list-style: none;
	padding: 20px;
	max-height: 400px;
	overflow-y: scroll;	

}
.list_attach li {
	padding-bottom: 5px;
    padding-top: 5px;
}
.drag_attach {
	width: 100%;
	height: 50px;
	background-color: rgb(244, 244, 244);
	text-align: center;
	font-size: 0.8rem;
	line-height: 4em;
	cursor: pointer;
}
#idkiosk.is-invalid  {
	display:block
}

#msg.is-invalid .invalid-feedback {
 display:block
}
.initials {
	color: white;
	padding: 13px;
	font-weight: lighter;
}
.initialsTable {
    color: white;
    top: 17px;
    position: relative;
    text-align: center;
}
.initialsl {

	color: white;
	position: relative;
	top: 5px;
	font-weight: lighter;
}

.fa-circle-notch {
	margin: 20px;
	color: #f0f0f0;
}
.table-report1 .dataTables_filter {
	visibility: hidden;
}
.progress-bar {
}

.reportbutton button.btn-default {
	border: 1px solid #deebfa !important;
	background-color: white !important;
	padding: 5px 20px 5px 20px !important;	
	text-transform: capitalize;
}

.reportFilter {
	margin-bottom: 20px;
}
.reportbutton .calendar .calendar-header {
	margin-bottom: 0px!important;
}

@-webkit-keyframes closeWindow {
	0% {
	  opacity: 1;
	}
	100% {
	  opacity: 0;
	}
  }
  
  @keyframes closeWindow {
	0% {
	  opacity: 1;
	}
	100% {
	  opacity: 0;
	}
  }
  
  .ril__outer {
	background-color: rgba(0, 0, 0, 0.85);
	outline: none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	width: 100%;
	height: 100%;
	-ms-content-zooming: none;
	-ms-user-select: none;
	-ms-touch-select: none;
	-ms-touch-action: none;
		touch-action: none;
  }
  
  .ril__outerClosing {
	opacity: 0;
  }
  
  .ril__inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  }
  
  .ril__image,
  .ril__imagePrev,
  .ril__imageNext {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	max-width: none;
	-ms-content-zooming: none;
	-ms-user-select: none;
	-ms-touch-select: none;
	-ms-touch-action: none;
		touch-action: none;
  }
  
  .ril__imageDiscourager {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
  }
  
  .ril__navButtons {
	border: none;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 20px;
	height: 34px;
	padding: 40px 30px;
	margin: auto;
	cursor: pointer;
	opacity: 0.7;
  }
  .ril__navButtons:hover {
	opacity: 1;
  }
  .ril__navButtons:active {
	opacity: 0.7;
  }
  
  .ril__navButtonPrev {
	left: 0;
	background: rgba(0, 0, 0, 0.2)
	  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
	  no-repeat center;
  }
  
  .ril__navButtonNext {
	right: 0;
	background: rgba(0, 0, 0, 0.2)
	  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
	  no-repeat center;
  }
  
  .ril__downloadBlocker {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
	background-size: cover;
  }
  
  .ril__caption,
  .ril__toolbar {
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	left: 0;
	right: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
		-ms-flex-pack: justify;
			justify-content: space-between;
  }
  
  .ril__caption {
	bottom: 0;
	max-height: 150px;
	overflow: auto;
  }
  
  .ril__captionContent {
	padding: 10px 20px;
	color: #fff;
  }
  
  .ril__toolbar {
	top: 0;
	height: 50px;
  }
  
  .ril__toolbarSide {
	height: 50px;
	margin: 0;
  }
  
  .ril__toolbarLeftSide {
	padding-left: 20px;
	padding-right: 0;
	-webkit-box-flex: 0;
		-ms-flex: 0 1 auto;
			flex: 0 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  
  .ril__toolbarRightSide {
	padding-left: 0;
	padding-right: 20px;
	-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
			flex: 0 0 auto;
  }
  
  .ril__toolbarItem {
	display: inline-block;
	line-height: 50px;
	padding: 0;
	color: #fff;
	font-size: 120%;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
  }
  
  .ril__toolbarItemChild {
	vertical-align: middle;
  }
  
  .ril__builtinButton {
	width: 40px;
	height: 35px;
	cursor: pointer;
	border: none;
	opacity: 0.7;
  }
  .ril__builtinButton:hover {
	opacity: 1;
  }
  .ril__builtinButton:active {
	outline: none;
  }
  
  .ril__builtinButtonDisabled {
	cursor: default;
	opacity: 0.5;
  }
  .ril__builtinButtonDisabled:hover {
	opacity: 0.5;
  }
  
  .ril__closeButton {
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
	  no-repeat center;
  }
  
  .ril__zoomInButton {
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
	  no-repeat center;
  }
  
  .ril__zoomOutButton {
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
	  no-repeat center;
  }
  
  .ril__outerAnimating {
	-webkit-animation-name: closeWindow;
			animation-name: closeWindow;
  }
  
  @-webkit-keyframes pointFade {
	0%,
	19.999%,
	100% {
	  opacity: 0;
	}
	20% {
	  opacity: 1;
	}
  }
  
  @keyframes pointFade {
	0%,
	19.999%,
	100% {
	  opacity: 0;
	}
	20% {
	  opacity: 1;
	}
  }
  
  .ril__loadingCircle {
	width: 60px;
	height: 60px;
	position: relative;
  }
  
  .ril__loadingCirclePoint {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
  }
  .ril__loadingCirclePoint::before {
	content: '';
	display: block;
	margin: 0 auto;
	width: 11%;
	height: 30%;
	background-color: #fff;
	border-radius: 30%;
	-webkit-animation: pointFade 800ms infinite ease-in-out both;
			animation: pointFade 800ms infinite ease-in-out both;
  }
  .ril__loadingCirclePoint:nth-of-type(1) {
	-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
  }
  .ril__loadingCirclePoint:nth-of-type(7) {
	-webkit-transform: rotate(180deg);
			transform: rotate(180deg);
  }
  .ril__loadingCirclePoint:nth-of-type(1)::before,
  .ril__loadingCirclePoint:nth-of-type(7)::before {
	-webkit-animation-delay: -800ms;
			animation-delay: -800ms;
  }
  .ril__loadingCirclePoint:nth-of-type(2) {
	-webkit-transform: rotate(30deg);
			transform: rotate(30deg);
  }
  .ril__loadingCirclePoint:nth-of-type(8) {
	-webkit-transform: rotate(210deg);
			transform: rotate(210deg);
  }
  .ril__loadingCirclePoint:nth-of-type(2)::before,
  .ril__loadingCirclePoint:nth-of-type(8)::before {
	-webkit-animation-delay: -666ms;
			animation-delay: -666ms;
  }
  .ril__loadingCirclePoint:nth-of-type(3) {
	-webkit-transform: rotate(60deg);
			transform: rotate(60deg);
  }
  .ril__loadingCirclePoint:nth-of-type(9) {
	-webkit-transform: rotate(240deg);
			transform: rotate(240deg);
  }
  .ril__loadingCirclePoint:nth-of-type(3)::before,
  .ril__loadingCirclePoint:nth-of-type(9)::before {
	-webkit-animation-delay: -533ms;
			animation-delay: -533ms;
  }
  .ril__loadingCirclePoint:nth-of-type(4) {
	-webkit-transform: rotate(90deg);
			transform: rotate(90deg);
  }
  .ril__loadingCirclePoint:nth-of-type(10) {
	-webkit-transform: rotate(270deg);
			transform: rotate(270deg);
  }
  .ril__loadingCirclePoint:nth-of-type(4)::before,
  .ril__loadingCirclePoint:nth-of-type(10)::before {
	-webkit-animation-delay: -400ms;
			animation-delay: -400ms;
  }
  .ril__loadingCirclePoint:nth-of-type(5) {
	-webkit-transform: rotate(120deg);
			transform: rotate(120deg);
  }
  .ril__loadingCirclePoint:nth-of-type(11) {
	-webkit-transform: rotate(300deg);
			transform: rotate(300deg);
  }
  .ril__loadingCirclePoint:nth-of-type(5)::before,
  .ril__loadingCirclePoint:nth-of-type(11)::before {
	-webkit-animation-delay: -266ms;
			animation-delay: -266ms;
  }
  .ril__loadingCirclePoint:nth-of-type(6) {
	-webkit-transform: rotate(150deg);
			transform: rotate(150deg);
  }
  .ril__loadingCirclePoint:nth-of-type(12) {
	-webkit-transform: rotate(330deg);
			transform: rotate(330deg);
  }
  .ril__loadingCirclePoint:nth-of-type(6)::before,
  .ril__loadingCirclePoint:nth-of-type(12)::before {
	-webkit-animation-delay: -133ms;
			animation-delay: -133ms;
  }
  .ril__loadingCirclePoint:nth-of-type(7) {
	-webkit-transform: rotate(180deg);
			transform: rotate(180deg);
  }
  .ril__loadingCirclePoint:nth-of-type(13) {
	-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
  }
  .ril__loadingCirclePoint:nth-of-type(7)::before,
  .ril__loadingCirclePoint:nth-of-type(13)::before {
	-webkit-animation-delay: 0ms;
			animation-delay: 0ms;
  }
  
  .ril__loadingContainer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
  }
  .ril__imagePrev .ril__loadingContainer,
  .ril__imageNext .ril__loadingContainer {
	display: none;
  }
  
  .ril__errorContainer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	color: #fff;
  }
  .ril__imagePrev .ril__errorContainer,
  .ril__imageNext .ril__errorContainer {
	display: none;
  }
  
  .ril__loadingContainer__icon {
	color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
  }
  .ReactModalPortal {
	z-index: 9999;
    position: fixed;
  }
  figure {width: 40px;}


  .breadcrumb {background-color: transparent; font-size: 0.8em;}

.dropdown-toggle::after {
	border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: .3em solid transparent;
    position: relative;
    right: 0px;
    float: right;
    height: 24px;
    top: 9px;
}
.card-body p {
	font-weight: 400;
}
.form-check {
	float: left;
	padding-bottom: 10px;
	width: 50%;
}
div[name="amount"] .input-group-prepend {
	width: 55px;
}
.navbar .dropdown-menu a {
	font-weight: 600;
}
.navbar-expand-md .navbar-nav .dropdown-menu {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}
.dropdown-divider {
	padding-bottom: 5px;
}
.dropdown-item a:hover, a.nav-link:hover {
	color: rgb(241, 37, 139)!important;
}
.btnX {
	float: right;
	font-size: 25px;
	padding: 0;
	margin: 0;
	border: none !important;
	color: black !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.overflow_div {
	position: absolute;
	background: transparent;
	width: 95%;
	height: 89px;
	z-index: 99;
}
.border1 {
	border: 1px solid #A1B5CE;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 10px;
	border-radius: 5px;
	font-size: 13px;
	width: 100%;
}